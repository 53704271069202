<template>
  
  <div>
    <Header>header2</Header>
    <slot />
    <Footer>footer2</Footer>
  </div>
</template>
<script setup lang="ts">
const layoutCustomProps = useAttrs();
console.log(layoutCustomProps.title);
const foo = ()=>{
  return 'test foo'
}
</script>
<style lang="scss" scoped></style>
